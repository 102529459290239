export const extractProfileByLanguage = (language, profileList) => {
  for (const profile of profileList) {
    if (!profile.language) {
      alert("Profile is has no language attribute (modify your graphql query)");
    }
    if (profile.language === language) {
      return profile;
    }
  }
};
